export default {
  addRoom: 'Add Room',
  additives: 'Additives',
  admin: 'Admin',
  addEditOrDeleteExistingRooms: 'Add, Edit, or Delete Existing Rooms',
  rooms: 'Rooms',
  auditLog: 'Event Log',
  batches: 'Batches',
  ccrsLog: 'CCRS Log',
  chooseYourState: 'Choose your State',
  conversions: 'Conversions',
  dashboard: 'Dashboard',
  default: 'Default',
  delete: 'Delete',
  edit: 'Edit',
  employees: 'Employees',
  errors: 'Errors',
  errorLog: 'Error Log',
  totalRows: 'Total Rows',
  screenshots: "Screenshots",
  results: "Results",
  rowsProcessed: 'Rows Processed',
  file: 'File',
  lcbManifest: 'LCB Manifest',
  logs: 'Logs',
  csvUploads: 'CSV Uploads',
  ccrsSuperadmin: 'CCRS Superadmin',
  manifestWebforms: 'Manifest Webforms',
  manifestWebformSuperadmin: 'Superadmin Manifests',
  failedAttempt: 'Failed attempt',
  processing: 'Processing',
  statusAuthFailure: 'Authentication Failed',
  statusErrorsReconciled: 'Errors Reconciled',
  statusReprocessing: 'Reprocessing',
  processingErrors: 'Processing Errors',
  processingFailed: 'processingFailed',
  filedDate: 'Filed Date',
  growFlow: 'GrowFlow',
  home: 'Home',
  id: 'ID',
  import: 'Import',
  importType: 'Import Type',
  inactiveLicense:
    'License is in-active. Work with support to turn this license back on if needed.',
  inboundOrders: 'Inbound Orders',
  insights: 'Insights (Beta)',
  inventory: 'Inventory',
  item: 'item',
  labelsTemplates: 'Labels/Templates',
  licenseNumber: 'License Number',
  licenseType: 'License Type',
  mode: 'Mode',
  editDelete: 'Edit/Delete',
  newOrder: 'New Order',
  newPreorder: 'New Preorder',
  newQaSample: 'New QA Sample',
  notes: 'Notes',
  oops: 'Ooops.',
  orders: 'Orders',
  overview: 'Overview',
  perpetual: 'Perpetual',
  plants: 'Plants',
  printing: 'Printing',
  productionMode: 'Production Mode',
  products: 'Products',
  qaSamples: 'QA Samples',
  region: 'Region',
  reports: 'Reports',
  roomId: 'Room ID',
  roomName: 'Room Name',
  roomType: 'Room Type',
  roomPurpose: 'Room Purpose',
  save: 'Save',
  salesHistory: 'Sales History',
  selectLicenseToAccessAccount:
    'Select a license to access your GrowFlow account.',
  standAloneMode: 'Standalone mode',
  store: 'Store',
  strains: 'Strains',
  ccrs: 'CCRS',
  switchLicense: 'Switch License',
  tableName: 'Table Name',
  traceabilityApiConnected: 'Traceability API Connected',
  trainingMode: 'Training Mode',
  ubiNumber: 'UBI Number',
  vehicles: 'Vehicles',
  vendors: 'Vendors',
  errorMessages: {
    accountUser:
      'Your user account does not have access to the license you were previously using. Please select a valid license to proceed.',
    criticalBootError:
      'There was a critical error during application boot, our engineers have been notified. Please refresh the page.',
    DEFAULT_GENERIC: 'It was not possible to complete the operation.',
    devsNotifiedTryAgain:
      'Our developers have been notified. Please try again or reach out to support.',
    JURISDICTION_MISSING:
      'Looks like your account is not linked with a Jurisdiction. Please confirm you have the correct license available.',
    JURISDICTION_WRONG:
      'Looks like we cannot retrieve the license you were previously using.  Please reselect a valid license.',
    permissions:
      'You do not have access to a resource on this page, please contact your GrowFlow instance administrator to request access.  If you feel this is an error please contact GrowFlow support.',
    SERVER_ERROR:
      'Looks like our server did not responded or responded with an error. Please reload the page or contact us on support if the problem continues.',
    somethingWentWrong: 'Something went wrong :(',
    unauthorized: 'Unauthorized or stale Session. Reloading page...',
    USER_NOT_FOUND:
      'Looks like your user was not found in the current License. Please confirm you have the correct license available.',
    bulkPrinting: {
      itemHasNoLabel:
        'One of the items is missing a label. Please update that selection and retry.',
      itemHasNoQuantity: 'Quantity to print cannot be empty.',
      mustSelectPageTemplate: 'You need to select a label page format.',
      mustSelectPrinter: 'You need to select a printer.',
      selectAtLeastOne: 'You need to select at least 1 item.',
      tooManyItems: 'Please select fewer than 1000 items to print at a time.',
      tooManyLabelSizes: 'Only one label size can be printed at a time.',
      notPossibleToGeneratePDF:
        'It was not possible to generate your PDF. Please try again.',
    },
  },
  searchLicenses: 'Search Licenses...',
  search: 'Search',
  searchEllipsis: 'Search...',
  userSettings: 'User Settings',
  helpDocs: 'Help Docs',
  growFlowUniversity: 'GrowFlow University',
  feedback: 'Feedback',
  accountSettings: 'Account Settings',
  logout: 'Logout',
  noLicenseSelected: 'No license selected',
  avatarOfUser: (userName: string): string => `Avatar of ${userName}`,
  userAvatar: 'User Avatar',
  colon: ':',
  user: 'User',
  account: 'Account',
  licenseNum: 'License #',
  license: 'License',
  training: 'Training',
  production: 'Production',
  version: 'Version',
  bulkPrinting: 'Bulk Printing',
  selected: 'selected',
  print: 'Print',
  printToPdf: 'Print to PDF',
  label: 'Label',
  setLabel: 'Set Label',
  row: 'row',
  stage: 'Stage',
  orderStage: {
    new: 'New',
    readyToPack: 'Ready to pack',
    readyToLabel: 'Ready to Label',
    needToManifest: 'Need to Manifest',
    needToTransfer: 'Need to Transfer',
  },
  selectPageTemplate: 'Select page template',
  pageFormat: 'Page format',
  selectPrinter: 'Select printer',
  printer: 'Printer',
  orderHeader: 'Order header',
  selectOrderHeader: 'Select header (optional)',
  itemHeaderLabel: 'Item Header Label',
  orderNumber: 'Order #',
  idNumber: 'ID #',
  searchOrders: 'Search Orders',
  pageDoestNotExist: 'Sorry, the page you visited does not exist.',
  backHome: 'Back Home',
  labelDimensions: 'Label Dim',
  product: 'Product',
  vendor: 'Vendor',
  brand: 'Brand',
  strain: 'Strain',
  strainType: 'Strain Type',
  category: 'Category',
  lastPrinted: 'Last Printed',
  packed: 'Packed',
  packageSize: 'Pkg Size',
  quantity: 'QTY',
  selectLabelTemplate: 'Select Label',
  preview: 'Preview',
  labelPrinter: 'Label Printer',
  noLabelSelected: 'No Label Selected',
  selectedLabelNotFound: 'Selected label not found',
  labelDimensionsNotSet: 'Label dimensions not set',
  generatingPdf: "We're generating the PDF. It will be just a moment...",
  printJobStarted: 'The print job was successfully started.',
  darkMode: 'Dark Mode (BETA)',
  printLabels: 'Print Labels',
  clearAllFilters: 'Clear All Filters',
  clearAll: 'Clear All',
  selectAll: 'Select All',
  productCategories: 'Product Categories',
  productCategory: 'Product Category',
  printOptions: 'Print Options',
  summary: 'Summary',
  cancel: 'Cancel',
  reloadPage: 'Reload Page',
  applicationStartupError: 'Application Startup Error',
  applicationError: 'Application Error',
  type: 'Type',
  referAndEarn: 'Refer & Earn',
  filter: 'Filter',
  export: 'Export',
  categories: 'Categories',
  lineItems: 'Line-items',
  changePrintQty: 'Change Print Qty',
  totalLabels: 'Total Labels',
  numberPerLineItem: 'Number per Line-Item',
  selectFromLabelTemplates: 'Select from label templates...',
  pageLayout: 'Page Layout',
  singleLabelPerPage: 'Single Label Per Page',
  labelSize: 'Label Size',
  yes: 'Yes',
  no: 'No',
  areYouSureYouWantToDeleteThisRoom: 'Are you sure you want to delete this room?',
  plant: 'Plant',
  roomSuccessfullyCreated: 'Room successfully created',
  roomSuccessfullyUpdated: 'Room successfully updated',
  roomSuccessfullyDeleted: 'Room successfully deleted',
  showRoom: 'Show Room',
  manageEmployees: 'Manage Employees',
  email: 'Email',
  firstName: 'First Name',
  lastName: 'Last Name',
  dateOfBirth: 'Date of Birth',
  dateOfHire: 'Date of Hire',
  phoneNumber: 'Phone #',
  vehicle: 'Vehicle',
  driversLicense: `Driver's License`,
  invite: 'Invite',
  addEmployee: 'Add Employee',
  areYouSureYouWantToDeleteThisEmployee: 'Are you sure you want to delete this employee?',
  confirmCancelEditRoom: 'If you cancel you will lose your changes to this room.',
  employeeSuccessfullyInvited: 'Employee successfully invited',
  emailCannotBeEmpty: 'Email cannot be empty',
  employeeSuccessfullyCreated: 'Employee successfully created',
  employeeSuccessfullyUpdated: 'Employee successfully updated',
  employeeSuccessfullyDeleted: 'Employee successfully deleted',
  updateStrains: 'Update Strains',
  addStrain: 'Add Strain',
  strainId: 'Strain ID',
  strainName: 'Strain Name',
  sativaPercentage: 'Sativa %',
  indicaPercentage: 'Indica %',
  hide: 'Hide',
  show: 'Show',
  showHiddenStrains: 'Show Hidden Strains',
  nickname: 'Nickname',
  bulkActions: 'Bulk Actions',
  actions: 'Actions',
  visibility: 'Visibility',
  areYouSureYouWantToDeleteThisStrain: 'Are you sure you want to delete this strain?',
  strainSuccessfullyUpdated: 'Strain successfully updated',
  strainSuccessfullyCreated: 'Strain successfully created',
  strainSuccessfullyDeleted: 'Strain successfully deleted',
  confirmCancelEditStrain: 'If you cancel you will lose your changes to this strain.',
};
